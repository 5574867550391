import React, { useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

import * as webSocketActions from '../../../redux/actions/webSocketActions';

import TOC from '../../../components/TOC';

import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import ZipCodeField from '../ZipCodeField';
import SCLandingWrapper from '../../SCLandingWrapper';
import FindPlansLink from '../FindPlansLink';

import '../index.css';
import './index.css';

function Article({ siteConfig, serviceAddress }) {
  return (
    <div className="article-container">
      <ZipCodeField mobile buttonLabel={'Find Prepaid Plans'} />
      <h1>
        <div>No Deposit / Prepaid Electric Plans</div>
      </h1>

      <div className="page-container text-container prepaid">
        <h5 className="est-read-time">
          Published 03/19/2023 | Read time {'~ '}2 min
        </h5>
        <div className="page">
          <div className="inner">
            <TOC>
              <ZipCodeField
                intro={'Quickly find a prepaid electric plan for your home:'}
              />
            </TOC>
            <div className="article-text">
              <h2>Introduction</h2>
              <p>
                Prepaid electric plans have become increasingly popular in Texas
                as an alternative to traditional postpaid plans. Unlike
                traditional postpaid plans, where you receive a bill at the end
                of the month, with a prepaid plan, you make payments upfront,
                either daily, weekly, or monthly, depending on the plan you
                choose.
              </p>
              <p>
                Pre-paying for your electricity might seem intimidating at
                first, but it can allow you to take better control of your usage
                habits, which helps you save money in the long run. And, there
                is no credit check or deposit required, which means poor credit
                or no credit is not a concern.
              </p>
              <h2>How do prepaid electric plans work?</h2>
              <p>
                Prepaid electric plans require you to pay a specific dollar
                amount for electricity usage that you purchase upfront. You then
                use this prepaid balance to power your home until it runs out.
                When you need to refill your prepaid balance, you can do so
                online or through a mobile app.
              </p>{' '}
              <div className="parallax-image">
                <ParallaxProvider>
                  <Parallax speed={-10}>
                    <ElectricalServicesIcon className="bottom-icon" />
                  </Parallax>
                </ParallaxProvider>
              </div>
              <p>
                Since you have a specific amount of electricity usage that you
                purchase upfront, prepaid plans make you more aware of your
                consumption, which may allow you to take steps to conserve
                electricity to make your balance last longer.
              </p>
              <h2>What are the benefits of prepaid electric plans?</h2>
              <ul>
                <li>
                  <strong>No deposits or credit checks: </strong>With prepaid
                  electric plans, you don't have to worry about paying a deposit
                  or undergoing a credit check to establish service. All you
                  need to do is sign up for a prepaid plan, pay for your initial
                  usage, and you're good to go.
                </li>

                <li>
                  <strong>No surprises: </strong>With traditional postpaid
                  electric plans, you never know how much your bill will be
                  until you receive it. With a prepaid electric plan, you know
                  exactly how much you are spending upfront, so there are no
                  surprises.
                </li>
                <li>
                  <strong>Flexibility: </strong>Prepaid electric plans offer
                  flexibility, which is particularly beneficial if you're on a
                  tight budget. You can choose how much you want to spend on
                  electricity each month, and if you can't afford to make a
                  payment, your service won't be disconnected immediately.
                  You'll typically have a grace period of a few days to make a
                  payment before your service is disconnected.
                </li>
              </ul>
              <h2>Find a Prepaid Electric Plan</h2>
              <FindPlansLink planType={'prepaid'} />
            </div>
          </div>
        </div>
      </div>
      <SCLandingWrapper
        city={'dallas'}
        noHeader
        preFilter={{ rateType: { Prepaid: true } }}
        preFilterLabel="prepaid"
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    siteConfig: state.commonData.siteConfig,
    serviceAddress: state.serviceAddress
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      pageLoading: bindActionCreators(webSocketActions.pageLoading, dispatch)
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Article);
