import React from 'react';
import SEO from '../components/SEO';

import Article from '../components/Articles/Prepaid/index';

export default function ({}) {
  return (
    <>
      <SEO
        title={'No Deposit / Prepaid Electric Plans'}
        description={
          'Take full control of your monthly electric bill. No deposit required and no credit checks.'
        }
        brand={'sc'}
      />

      <Article />
    </>
  );
}
